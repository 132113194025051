<template>
  <div class="homeclass" v-wechat-title="$route.meta.title">
    <div class="top">
      <p class="hometip">{{ company == "" ? "首页" : company }}</p>
      <img class="white" src="../../assets/rectangle.png" alt="" />
      <!-- <img class="banner" src="../../assets/banner.png" alt="" /> -->
      <div style="position: absolute" class="swiper banner">
        <van-swipe :autoplay="5000" indicator-color="#fff">
          <van-swipe-item v-for="(image, index) in SwiperImg" :key="index">
            <img class="imgclass" :src="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <p class="msg" @click="$router.push('/systeminformation')" style="position: absolute;">
        <span class="zhan">站内信</span>
        <span class="zui">最新消息</span>
      </p>
    </div>
    <div class="ybd">
      <div class="beidiao">
        <p @click="goback('已完成背调')" class="wancheng">
          <span class="wan">已完成背调</span>
          <span class="cheng">{{ accomplish }}</span>
        </p>
        <p @click="goback('背调进行中')" class="jinxing">
          <span class="jin">背调进行中</span>
          <span class="xing">{{ proceed }}</span>
        </p>
        <p @click="goback('授权被拒绝')" class="jujue">
          <span class="ju">背调被拒</span>
          <span class="jue">{{ refuse }}</span>
        </p>
        <p @click="goback('未完成背调')" class="guoqi">
          <span class="guo">授权过期</span>
          <span class="qi">{{ pastdue }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      accomplish: "",
      proceed: "",
      refuse: "",
      pastdue: "",
      SwiperImg: [
        require("../../assets/home1.png"),
        require("../../assets/home2.png"),
      ],
      company: "",
    };
  },
  async created() {
    let than = this;

    await this.$http
      .post("/firm/v1/Background/reportList", {
        reqType: "profile",
        start_time: "",
        end_time: "",
        page: 3,
        article: 9999999,
        name: "",
        product_edition: "",
        survey: "",
      })
      .then((res) => {
        res = JSON.parse(res.data);
        
        if (res.code === 0) {
          than.list = res.data.list.data;
        }
      })
      .catch((eer) => {
        
      });

    this.$http
      .post("/firm/v1/userinfo/companyinfo", {
        reqType: "userinfo",
      })
      .then((res) => {
        
        this.company = JSON.parse(res.data).data.company;
        
      });

    await this.accomplish1();
  },

  methods: {
    // 判断数量逻辑代码
    accomplish1() {
      this.accomplish = this.list.filter((item, index) => {
        return item.survey == 4;
      }).length;
      this.proceed = this.list.filter((item, index) => {
        return item.survey == 2;
      }).length;
      this.refuse = this.list.filter((item, index) => {
        return item.survey == 3;
      }).length;
      this.pastdue = this.list.filter((item, index) => {
        return item.survey == 1;
      }).length;
    },
    goback(msg) {
      this.$router.push({
        name: "search",
        params: {
          select: msg,
        },
      });
    },
  },
};
</script>
<style scoped>
.imgclass {
  height: 100%;
  width: 100%;
}
.hometip {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.2rem;
  font-size: 0.34rem;
  color: #ffffff;
  white-space: nowrap;
}
.homeclass {
  height: 100%;
  background-color: #ffffff;
}
.van-swipe >>> .van-swipe__track {
  height: 3rem;
}
.top {
  width: 100%;
  height: 3.77rem;
  background: linear-gradient(180deg, #fb9f12 0%, #f0821a 100%);
}
.top .white {
  position: fixed;
  top: 2.54rem;
  width: 100%;
  height: 1.89rem;
}
.top .banner {
  position: fixed;
  top: 1.2rem;
  width: 6.83rem;
  height: 3.1rem;
  margin-left: 50%;
  transform: translate(-50%);
}
.msg {
  background-color: #ffffff;
}
.top .msg {
  background: url(../../assets/orthogon.png);
  background-size: 100%;
  width: 6.86rem;
  height: 0.8rem;
  margin-left: 0.3rem;
  position: fixed;
  top: 4.67rem;
  line-height: 0.8rem;
}
.top .msg .zhan {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-left: 0.31rem;
}
.top .msg .zui {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6e6e6e;
  margin-left: 0.18rem;
}
.ybd {
  position: absolute;
  top: 5.8rem;
  background: #fff;
  padding-bottom: 2.23rem;
  width: 100%;
}
.ybd .beidiao {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 1.95rem; */
  margin: auto;
  /* margin-bottom: 2.23rem; */
}
.beidiao .wancheng {
  background: url(../../assets/done.png);
  background-size: 100%;
  margin-left: 3%;
  width: 45.5%;
  height: 3.34rem;
  margin-right: 3%;
}

.beidiao .wancheng .wan {
  display: block;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff9362;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.34rem;
}
.beidiao .wancheng .cheng {
  display: block;
  font-size: 0.58rem;
  font-family: ArialMT;
  color: #ff5813;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.14rem;
}
.beidiao .jinxing {
  
  background-size: 100%;
  width: 45.5%;
  height: 3.34rem;
}
.beidiao .jinxing .jin {
  display: block;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #6bc34d;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.34rem;
}
.beidiao .jinxing .xing {
  display: block;
  font-size: 0.58rem;
  font-family: ArialMT;
  color: #6bc34d;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.14rem;
}
.beidiao .jujue {
  background: url(../../assets/refuse.png);
  background-size: 100%;
  width: 45.5%;
  height: 3.34rem;
  margin-top: 0.17rem;
  margin-right: 3%;
  margin-left: 3%;
}
.beidiao .jujue .ju {
  display: block;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fea80f;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.34rem;
}
.beidiao .jujue .jue {
  display: block;
  font-size: 0.58rem;
  font-family: ArialMT;
  color: #fea80f;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.14rem;
}
.beidiao .guoqi {
  background: url(../../assets/pastDue.png);
  background-size: 100%;
  width: 45.5%;
  height: 3.34rem;
  margin-top: 0.17rem;
}
.beidiao .guoqi .guo {
  display: block;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1677ff;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.34rem;
}
.beidiao .guoqi .qi {
  display: block;
  font-size: 0.58rem;
  font-family: ArialMT;
  color: #1677ff;
  line-height: 0.5rem;
  margin-left: 0.22rem;
  margin-top: 0.14rem;
}
</style>
